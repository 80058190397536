<template>
	<div>
		<div>
			<v-btn v-if="isText" color="success" @click="showTemplatesDialog = true">
				System Templates
			</v-btn>

			<v-btn
				v-else
				@click="showTemplatesDialog = true"
				:color="'success'"
				large
				icon
			>
				<v-icon large>
					mdi-file-cog
				</v-icon>
			</v-btn>
		</div>
		<v-dialog
			v-if="showTemplatesDialog"
			v-model="showTemplatesDialog"
			transition="dialog-bottom-transition"
			fullscreen
			scrollable
			persistent
			hide-overlay
		>
			<v-card>
				<v-card-title class="text-h5 transparent-3">
					{{ systemTemplates.data.length }} System Templates
					<v-spacer></v-spacer>

					<!-- <v-btn
						color="success"
						icon
						@click="createUpdateSystemTemplateDialogShow()"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn> -->

					<v-btn color="secondary" icon @click="getSystemTemplates()">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>

					<v-btn color="error" icon @click="showTemplatesDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-card
						v-for="(template, index) in systemTemplates.data"
						class="my-4 transparent-1"
						:key="index"
						elevation="1"
					>
						<v-card-title class="pa-2 ma-0 transparent-3">
							<h5>
								{{ template.payload.name }}
							</h5>
							<v-spacer></v-spacer>
							<div>
								<v-btn
									color="success"
									small
									icon
									@click="createUpdateSystemTemplateDialogShow(template, null)"
								>
									<v-icon>mdi-update</v-icon>
								</v-btn>
								<v-btn
									@click="deleteSystemTemplate(template.id)"
									v-if="template.payload.is_default == 'No'"
									color="error"
									icon
									small
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</div>
						</v-card-title>
						<v-card-text class="pa-2">
							<v-row>
								<v-col
									v-for="(option, i) in template.payload.body_options"
									:cols="colSize(4, 6, 12, 12)"
									:key="i"
								>
									<v-card class="transparent-1" elevation="1">
										<v-card-title class="pa-1 my-0 transparent-2">
											<h6>
												{{ option.name }}
											</h6>
											<v-spacer></v-spacer>
											<div>
												<v-tooltip v-if="option.is_selected == 'Yes'" bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-icon
															color="success"
															v-bind="attrs"
															v-on="on"
															small
															>mdi-check-circle</v-icon
														>
													</template>
													<span>
														This is the default selected option for the
														template.
													</span>
												</v-tooltip>
												<v-btn
													color="success"
													small
													icon
													@click="
														createUpdateSystemTemplateDialogShow(template, i)
													"
												>
													<v-icon small>mdi-update</v-icon>
												</v-btn>
											</div>
										</v-card-title>
										<v-card-text class="py-2" v-html="option.body">
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-card-actions>
						<v-row>
							<v-col cols="12">
								<v-pagination
									style="justify-content: center;"
									@input="getSystemTemplates"
									v-model="systemTemplates.current_page"
									:length="systemTemplates.last_page"
									:total-visible="5"
								></v-pagination>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card-text>
				<v-card-actions class="transparent-2">
					<v-btn
						block
						outlined
						color="green darken-1"
						@click="showTemplatesDialog = false"
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<template v-if="createUpdateSystemTemplateDialog">
			<createUpdateSystemTemplateDialog
				@refresh="refresh()"
				@closeDialog="createUpdateSystemTemplateDialogClose()"
				:dialogModel="createUpdateSystemTemplateDialog"
				:selectedModel="selectedTemplate"
				:selectedTemplateOptionIndexId="selectedTemplateOptionIndexId"
				:user="user"
				scrollable
			/>
		</template>
	</div>
</template>

<script>
	import createUpdateSystemTemplateDialog from "@/components/dialogs/createUpdateSystemTemplateDialog";

	export default {
		components: { createUpdateSystemTemplateDialog },
		props: {
			user: { required: true, default: null },
			isText: { required: false, default: true }
		},
		data() {
			return {
				systemTemplates: [],
				showTemplatesDialog: false,
				createUpdateSystemTemplateDialog: false,
				selectedTemplate: null,
				selectedTemplateOptionIndexId: null,
				isValidForm: false
			};
		},
		mounted() {
			this.getSystemTemplates();
		},
		computed: {},
		watch: {
			showTemplatesDialog(newVal) {
				if (newVal) this.getSystemTemplates();
			}
		},
		methods: {
			refresh() {
				this.getSystemTemplates();
				this.$emit("refresh");
			},
			createUpdateSystemTemplateDialogShow(template, optionIndexId = null) {
				this.selectedTemplateOptionIndexId = optionIndexId;
				this.selectedTemplate = template;
				this.createUpdateSystemTemplateDialog = true;
			},
			createUpdateSystemTemplateDialogClose() {
				this.selectedTemplate = null;
				this.createUpdateSystemTemplateDialog = false;
			},
			async getSystemTemplates(pageNumber = 1) {
				let data = {
					agent_id: this.user?.agent?.id
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/get-system-templates?page=${pageNumber}`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					// this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.systemTemplates = data.data.system_templates;
					}
				});
			},
			async deleteSystemTemplate(systemTemplateId) {
				if (!confirm("You sure you want to delete this custom system template."))
					return false;

				let data = {
					system_template_id: systemTemplateId
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/delete-system-template`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.getSystemTemplates();
					}
				});
			}
		}
	};
</script>

